<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>

<script>
import {WOW} from 'wowjs'

export default {
  mounted() {
    this.initializeWOW(); //initialize WOW for on scroll animations

    // remove fbclid from url when visiting from facebook
    if(/^\?fbclid=/.test(location.search)) location.replace(location.href.replace(/\?fbclid.+/, ""));
  },
  methods: {
    initializeWOW() {
      // Ensure WOW.js is initialized after the page has fully loaded
      window.onload = () => {
        new WOW({
          boxClass: 'wow',      // default
          animateClass: 'animated', // default
          offset: 250,
          mobile: true,       // default
          live: false         // default
        }).init();
      };
    }
  }
}
</script>
