<template>
  <section id="skills" class="section">
        <h2>Skills</h2>
        <p>I'm very proficient with <b>HTML</b>, <b>CSS</b>, and <b>JavaScript</b>, along with several frontend frameworks such as <b>Vue</b> and <b>React</b>. I am able to create responsive and user-friendly interfaces, while always striving to create visually appealing designs that not only look good, but also enhance the overall user experience.</p>
        <p>In addition to frontend development, I also possess a strong knowledge in backend development. My experience includes working with <b>Node.js</b>, <b>PHP</b>, <b>Laravel</b>, <b>ASP.NET Core</b>, as well as databases like <b>MySQL</b> or <b>SQL Server</b>, <b>Firebase</b> and <b>MongoDB</b>.</p>
        
        <div class="section-content">
          <h3>Skillset and technologies I'm familiar with:</h3>
          
          <div class="skill-showcase">
            <div class="skills-container">
              <div 
                v-for="skill in skills" 
                :class="isActive(skill.labels)" 
                class="skill wow animate__flipInY" 
                :data-wow-duration="getRandomInterval()" 
                :key="skill.name"
                @click="setFilters(skill.labels)"
                >
                <img :src="`/images/skills/${skill.img}`" alt="">
                {{ skill.name }}
              </div>            
            </div>

            <div class="filter-container">
              <button 
                :class="{'active': filtersSettings['frontend']}" 
                class="filter-btn wow animate__fadeInUp"
                data-wow-duration="1.2s"
                @click="toggleFilter('frontend')">Frontend</button>
              <button 
                :class="{'active': filtersSettings['backend']}" 
                class="filter-btn wow animate__fadeInUp"
                data-wow-duration="1.5s"
                @click="toggleFilter('backend')">Backend</button>
              <button 
                :class="{'active': filtersSettings['others']}" 
                class="filter-btn wow animate__fadeInUp"
                data-wow-duration="1.8s"
                @click="toggleFilter('others')">Others</button>
              <button 
                v-show="showClearFilters" 
                class="clear-filter-btn" 
                @click="clearFilters">Clear filters</button>
            </div>
          </div>
        </div>
       
      </section>
</template>

<script>

export default {
  data() {
    return {
      filtersSettings: null,
      skills: [
        {name:'HTML', img: 'html.svg', labels: ['frontend']},
        {name:'CSS', img: 'css.svg', labels: ['frontend']},
        {name:'JavaScript', img: 'javascript.svg', labels: ['frontend']},
        {name:'Vue', img: 'vuejs.svg', labels: ['frontend']},
        {name:'React', img: 'react.svg', labels: ['frontend']},
        {name:'Bootstrap', img: 'bootstrap.svg', labels: ['frontend']},
        {name:'Tailwind CSS', img: 'tailwind.svg', labels: ['frontend']},
        {name:'Laravel', img: 'laravel.svg', labels: ['backend']},
        {name:'PHP', img: 'php.svg', labels: ['backend']},
        {name:'NodeJS', img: 'node-js.svg', labels: ['backend']},
        {name:'ASP.NET Core', img: 'dotnetcore.svg', labels: ['backend']},
        {name:'SQL', img: 'sql.svg', labels: ['backend']},
        {name:'Firebase', img: 'firebase.svg', labels: ['backend']},
        {name:'MongoDB', img: 'mongodb.svg', labels: ['backend']},
        {name:'Wordpress', img: 'wordpress.svg', labels: ['others']},
        {name:'SEO', img: 'seo.svg', labels: ['others']},
        {name:'Git', img: 'git.svg', labels: ['others']},
        {name:'Cloudflare', img: 'cloudflare.svg', labels: ['others']},
        {name:'UI/UX', img: 'uiux.svg', labels: ['others']}
      ]
    }
  },
  created() {
    this.clearFilters() //set filterSettings to default
  },
  computed: {
    isMobileOrTablet: () => window.innerWidth < 1024,
    showClearFilters() {
      //if one of the filters is active show clear filters button
      for(let filter in this.filtersSettings) {
         if(this.filtersSettings[filter]) return true
      }
      return false
    }
  },
  methods: {
    isActive(labels) {
      if (!labels) return ''; //in case a skill doesnt have labels

      // if one of the labels is TRUE from filterSettings, return "active" class
      return labels.some(label => this.filtersSettings[label]) ? 'active' : '';
    },
    setFilters(labels) {
      this.clearFilters()
      // for every label the skills has, activate / add it as filter
      labels.forEach(label => {
        this.filtersSettings[label] = true
      })
    },
    toggleFilter(filter) {
      if(this.filtersSettings)
      this.filtersSettings[filter] = !this.filtersSettings[filter];
    },
    clearFilters() {
      // initialize / set filterSettings to default
      this.filtersSettings = {
        frontend: false,
        backend: false,
        others: false
      }
    },
    getRandomInterval() {
      //get and return random number from 0.8 to 3 as seconds
      return `${Math.random() * (3 - 0.8) + 0.8}s`
    }
  }
}
</script>

<style scoped>
  #skills {
   margin-top: 50px; 
  }

  #skills .section-content > h3 {
    display: flex;
    align-items: stretch;
    position: relative;
    text-align: left;
    margin: 1em 0;
    font-size: 1.05em;
    font-weight: 400;
    color: rgb(187, 186, 186);
  }
  #skills .section-content > h3::before {
    content: '\00a0';
    margin: 0 7px;
    padding-right: 1px;
    background-color: #0abf80;
  }

  .skill-showcase {
    display: flex;
    flex-direction: column;
    max-width: 940px;
  }

  .skills-container {
    display: flex;
    justify-content: strech;
    /* align-items: stretch; */
    margin: 30px 0;
    gap: 10px;
    flex-wrap: wrap;
  }

  .skills-container .skill {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: solid 1px #3e3e3e;
    padding: 10px 12px;
    border-radius: 4px;
    color: #676867;
    font-size: 18px;
    /* flex-grow: 1; */
    filter: grayscale(1);
    transition: 1s, transform 0.2s, box-shadow 0.2s;
    /* cursor: pointer; */
    user-select: none;
  }
  .skills-container .skill img {
    width: 30px;
    transition: 0.5s;
  }
  .skills-container .skill:hover {
    transition: 0.2s;
    filter: unset !important;
    border-color: #0abf80;
    color: #b0b0b0;
  }

  .skills-container .skill.active {
    background-color: black;
    border-color: #0abf80;
    filter: unset !important;
    color: #b0b0b0;
    transform: scale(1.03) translateY(-2px);
    box-shadow: 0 0 10px rgba(59, 155, 107, 0.19);
    transition: 0.2s;
  }

  .filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-top: 25px;
    border-top: 1px solid #2b2b2b;
  }
  .filter-container .filter-btn {
    background: #262d30;
    border: none;
    padding: 7px 10px;
    border-radius: 100px;
    cursor: pointer;
    color: #676867;
    font-size: 15px;
    transition: 0.15s;
  }
  .filter-container .filter-btn:hover {
    background: #2e373a;
    color: #b0b0b0;

  }
  .filter-container .filter-btn.active {
    background-color: #0abf80;
    color: black;
    scale: 1.06;
    transform: translateY(-1px);
  }

  .filter-container .clear-filter-btn {
    background: none;
    border: none;
    color: gray;
    margin-left: 5px;
    padding-left: 10px;
    border-left: 1px solid #2b2b2b;
    cursor: pointer;
    font-size: 15px;
  }
  .filter-container .clear-filter-btn:hover {
    color: rgb(112, 170, 197);
  }

  .skills-container .skill:active {
      transition: 0.02s;
      transform: scale(1.13) translateY(-4px);
      z-index: 99;
    }

  @media (min-width: 1024px) {
    .skills-container .skill:hover img { transform: translateY(-4px); transition: 0.2s;}
  }

  @media (max-width: 790px) {
    .skills-container {
      justify-content: center;
    }

    .skills-container .skill {
      font-size: 16px;
    }
    .skills-container .skill img {
      width: 25px;
    }

   
  }
  
  @media (max-width: 600px) {
    #skills .section-content > h3::before {
      font-size: 0.8em;
      padding: 0;
    }

    .skills-container {
      order: 2;
    }

    .filter-container {
      justify-content: center;
      padding-bottom: 20px;
      border: none;
      border-bottom: 1px solid #2b2b2b;
    }

    .filter-container .clear-filter-btn {
      display: none !important;
    }
  }
</style>