import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import './assets/css/main.css'
import './assets/js/particles';

     
const app = createApp(App);

app.use(router);

// app.config.productionTip = false

app.mount('#app');