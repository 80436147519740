<template>
  <section id="work" class="section">
        <h2>Work</h2>
        <p>Throughout my experience as a developer, I have developed and worked on a variety of websites, applications, and projects. Each one of these projects has challenged me in unique ways, helping me to learn and improve my skills over the years.</p>
        <div class="section-content">
          <h3>These are just a few projects I have worked on that showcase my skills:</h3>
          <div class="projects-container">

            <div v-for="project in projects" 
              :class="`project wow ${project.animation.name}`"
              :data-wow-duration="project.animation.duration"
              :key="project"
            >
              <a target="_blank" :href="project.url">
                <div class="project-overlay"></div>
                <img class="project-img" :src="`/images/projects/webp/${project.img}`" :alt="project.alt"/>
                <h3 class="project-title">{{project.title}}</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <!-- <div class="project wow animate__fadeInLeft" data-wow-duration="1.3s">
              <a target="_blank" href="https://www.407archstory.com">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/407archstory.webp" alt="Rilind Tasholli 407 ArchStory Project"/>
                <h3 class="project-title">407 ArchStory</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <div class="project wow animate__fadeInDown" data-wow-duration="1.3s">
              <a target="_blank" href="https://www.ardareigroup.com">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/ardarei.webp" alt="Rilind Tasholli Arda Rei Project"/>
                <h3 class="project-title">Arda Rei</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <div class="project wow animate__fadeInRight" data-wow-duration="1.3s">
              <a target="_blank" href="https://github.com/rilindtasholli/e-blooddonor">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/e-blooddonor.webp" alt="Rilind Tasholli E-BloodDonor Project"/>
                <h3 class="project-title">E-BloodDonor</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <div class="project wow animate__fadeInLeft" data-wow-duration="1.3s" >
              <a target="_blank" href="https://rilindtasholli.github.io/weather-app/">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/weather-app.webp" alt="Rilind Tasholli Weather App Project"/>
                <h3 class="project-title">Weather App</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <div class="project wow animate__fadeInUp" data-wow-duration="1.3s" >
              <a target="_blank" href="https://chopchop-restaurant.netlify.app/">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/chop-chop-restaurant.webp" alt="Rilind Tasholli ChopChop Restaurant Project"/>
                <h3 class="project-title">ChopChop Restaurant</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <div class="project wow animate__fadeInRight" data-wow-duration="1.3s" >
              <a target="_blank" href="https://easy-ride-car-rental.netlify.app/">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/easy-ride.webp" alt="Rilind Tasholli EasyRide Car Rental Project"/>
                <h3 class="project-title">EasyRide</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <div class="project wow animate__fadeInLeft" data-wow-duration="1.3s">
              <a target="_blank" href="https://rilindtasholli.github.io/crypto-chain/">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/crypto-chain.webp" alt="Rilind Tasholli CryptoChain Project"/>
                <h3 class="project-title">CryptoChain</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <div class="project wow animate__fadeInUp" data-wow-duration="1.3s">
              <a target="_blank" href="https://rilindtasholli-github-app.vercel.app/">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/github-app.webp" alt="Rilind Tasholli GitHub App Project"/>
                <h3 class="project-title">GitHub App</h3>
                <span class="view-project">view project</span>
              </a>
            </div>

            <div class="project wow animate__fadeInRight" data-wow-duration="1.3s">
              <a target="_blank" href="https://rilindtasholli-trello-clone-app.vercel.app/">
                <div class="project-overlay"></div>
                <img class="project-img" src="@/assets/img/projects/webp/trello-app.webp" alt="Rilind Tasholli Trello App Project"/>
                <h3 class="project-title">Trello App</h3>
                <span class="view-project">view project</span>
              </a>
            </div> -->
          </div>
          <div class="info-text">
            <div class="left-border"></div>
            <p>If you're interested to see more examples of my work, please feel free to <span @click="$emit('go-section', 'contact')">contact</span> me. I would be happy to share with you other projects I have worked on.</p>
          </div>
          <!-- <p style="margin: unset; text-align: center;">For more reference of my work, please contact me so I can list you with other projects I have worked on. </p> -->
        </div>
      </section>
</template>

<script>
  export default {
    data() {
      return {
        allProjects: [
          {
            title: '407 ArchStory',
            url: 'https://www.407archstory.com',
            img: '407archstory.webp',
            alt: 'Rilind Tasholli 407 ArchStory Project',
            animation: {
              name: 'animate__fadeInLeft',
              duration: '1.3s'
            },
            visible: true
          },
          {
            title: 'Arda Rei',
            url: 'https://www.ardareigroup.com',
            img: 'ardarei.webp',
            alt: 'Rilind Tasholli Arda Rei Project',
            animation: {
              name: 'animate__fadeInDown',
              duration: '1.3s'
            },
            visible: true
          },
          {
            title: 'E-BloodDonor',
            url: 'https://github.com/rilindtasholli/e-blooddonor',
            img: 'e-blooddonor.webp',
            alt: 'Rilind Tasholli E-BloodDonor Project',
            animation: {
              name: 'animate__fadeInRight',
              duration: '1.3s'
            },
            visible: true
          },
          {
            title: 'Weather App',
            url: 'https://rilindtasholli.github.io/weather-app/',
            img: 'weather-app.webp',
            alt: 'Rilind Tasholli Weather App Project',
            animation: {
              name: 'animate__fadeInLeft',
              duration: '1.3s'
            },
            visible: false
          },
          {
            title: 'Music App',
            url: 'https://rilindtasholli-music-app.vercel.app/',
            img: 'music-app.webp',
            alt: 'Rilind Tasholli Music App Project',
            animation: {
              name: 'animate__fadeInLeft',
              duration: '1.3s'
            },
            visible: true
          },
          {
            title: 'ChopChop Restaurant',
            url: 'https://chopchop-restaurant.netlify.app/',
            img: 'chop-chop-restaurant.webp',
            alt: 'Rilind Tasholli ChopChop Restaurant Project',
            animation: {
              name: 'animate__fadeInUp',
              duration: '1.3s'
            },
            visible: true
          },
          {
            title: 'EasyRide',
            url: 'https://easy-ride-car-rental.netlify.app/',
            img: 'easy-ride.webp',
            alt: 'Rilind Tasholli EasyRide Car Rental Project',
            animation: {
              name: 'animate__fadeInRight',
              duration: '1.3s'
            },
            visible: true
          },
          {
            title: 'CryptoChain',
            url: 'https://rilindtasholli.github.io/crypto-chain/',
            img: 'crypto-chain.webp',
            alt: 'Rilind Tasholli CryptoChain Project',
            animation: {
              name: 'animate__fadeInLeft',
              duration: '1.3s'
            },
            visible: true
          },
          {
            title: 'GitHub App',
            url: 'https://rilindtasholli-github-app.vercel.app/',
            img: 'github-app.webp',
            alt: 'Rilind Tasholli GitHub App Project',
            animation: {
              name: 'animate__fadeInUp',
              duration: '1.3s'
            },
            visible: true
          },
          {
            title: 'Trello App',
            url: 'https://rilindtasholli-trello-clone-app.vercel.app/',
            img: 'trello-app.webp',
            alt: 'Rilind Tasholli Trello App Project',
            animation: {
              name: 'animate__fadeInRight',
              duration: '1.3s'
            },
            visible: true
          }
        ]
      }
    },
    computed: {
      projects() {
        return this.allProjects.filter(project => project.visible);
      }
    }
  }
</script>

<style scoped>

  #work .section-content > h3 {
    display: flex;
    align-items: stretch;
    position: relative;
    text-align: left;
    margin: 1em 0;
    font-size: 1.05em;
    font-weight: 400;
    color: rgb(187, 186, 186);
  }
  #work .section-content > h3::before {
    content: '\00a0';
    /* height: 100%; */
    margin: 0 7px;
    padding-right: 1px;
    background-color: #0abf80;
  }

  .projects-container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 3px;

    margin: 35px 0 60px;
  }

  .project {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: #232729;
    outline: 1px solid tra;
    overflow: hidden;
    cursor: pointer;
    width: 32.99%;
    transition: .3s;
  }
  /* .project:hover:not(:focus) {
    outline: 1px solid #0abf80b6;
    transition: .6s;
  }
  .project:active:not(:focus) .project-overlay {
      background-color: rgba(29, 53, 46, 0.7);
      transition: 0s;
  } */


  .project-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 15, 17, 0.2);
    transition: .5s;
    z-index: 2;
  }

  .project-img {
    width: 100%;
    padding: 30px 15px 50px;
    transition: .5s;
  }
  .project-title {
    bottom: 0;
    position: absolute;
    /* border: dashed 1px green; */
    transition: all .5s, background-color 1.67s;
    z-index: 3;
    width: 100%;
    padding: 10px 5px;
    background-color: #353f442d;
    letter-spacing: 1px;
    font-weight: normal;
    user-select: none;
  }

  .view-project {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 150%);
    opacity: 0;
    font-size: .97em;
    z-index: 3;
    transition: .3s;
    user-select: none;
    /* text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.95); */
  }

  .view-project:before {
    font-family: 'Font Awesome 6 Free';
    content: '\f06e';
    margin-right: 7px;
    transition: .35s;
  }

  .info-text {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: unset;
    margin-bottom: 1em;
    /* text-align: center */
  }
  .info-text p {
    margin: 0;
  }
  .info-text .left-border {
    margin: 0 7px;
    padding-right: 5px;
    background-color: #526870;
  }

  /* .info-text::before {
    content: "\00a0";
    display: inline-block;
    margin: 0 7px;
    padding-right: 1px;
    background-color: #526870;
  } */

  .info-text span{
    cursor: pointer;
    color: rgb(112, 170, 197);
  }

  /*---------------- Desktop Only media query ----------------*/

  @media (min-width: 1024px) {

    /* apply on hover effect only for desktop */

    .project:hover {
      outline: 1px solid #0abf80b6;
      transition: 0.5s;
    }
    .project:hover .project-title {
      bottom: 50%;
      transform: translateY(50%) scale(1.15);
      padding: 13px 5px;
      background-color: transparent;
      letter-spacing: 3px;
      transition: .3s; 
      /* box-shadow: 0px 2px 5px rgba(15, 19, 20, 0.25); */
    }
    .project:hover .project-overlay {
      background-color: rgba(29, 53, 46, 0.7);
      transition: .3s;
    }
    .project:hover .project-img {
      /* filter: blur(.08em); */
      transform: scale(1.13) rotate(3deg);
    }

    .project:hover .view-project {
      opacity: 10.9;
      transition: .8s;
    }
    .project:hover .view-project:before {
      color: rgb(10, 191, 128);
    }
  }

   /*-------------- Tablet/Mobile media queries --------------*/

   @media (max-width: 1024px) {
    .project:active:not(:focus),
    .project:active:not(:hover) {
      outline: 1px solid #0abf80b6;
      transition: 0s;
    }
    .project:active:not(:focus) .project-overlay,
    .project:active:not(:hover) .project-overlay {
        background-color: rgba(29, 53, 46, 0.7);
        transition: 0s;
    }
   }

  @media (max-width: 800px) {
    .project {
      width: 48.99%;
    }
  }

  @media (max-width: 600px) {
    .projects-container {
      gap: 10px;
    }
    .project {
      width: 100%;
    }

    #work .section-content > h3::before{
      font-size: 0.8em;
      padding: 0;
    }
    .info-text .left-border {
      padding-right: 3px;
    }
  }


</style>