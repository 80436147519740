<template>
  <section id="about" class="section">
      <h2>About</h2>
      <div class="section-content">
        <img class="desktop-image wow animate__fadeInRight" data-wow-duration="1.13s" src="@/assets/img/portraits/webp/rilind-tasholli-about-portrait.webp" alt="Rilind Tasholli Developer About"/>
        <p>Hi, my name is <strong class="rilindtasholli">Rilind Tasholli</strong>, although born in Switzerland, I'm a proud Albanian from Kosovo with a rich cultural background that has shaped who I am today. I've been passionate about programming since I was young, and it's been an important part of my life ever since. I started by developing desktop applications and over time, I shifted my focus to web development.</p>
        <img class="mobile-image wow animate__fadeInLeft" data-wow-duration="1.13s" src="@/assets/img/portraits/webp/rilind-tasholli-about-portrait_mobile.webp" alt="Rilind Tasholli Developer About"/>
        <p>As a developer, I'm always looking for ways to improve my skills and learn new things. I consider myself a bit of a perfectionist, and always try to do my job as best as possible. I believe that there's always room for improvement, and I'm constantly looking for ways to challenge myself and grow as a developer.</p>
        <p>During my free time, I enjoy working on side projects and also solving coding challenges, allowing me to sharpen my problem-solving skills and learn new programming concepts in a fun and interactive way. When I'm not in front of the computer, I really enjoy spending time in nature and engaging in outdoor activies such as hiking and camping. I find that being in nature is a great way to clear my mind and recharge my batteries.</p>
        <p>Overall, I'm dedicated to continuous self-improvement and value the importance of surrounding myself with positive individuals who inspire and motivate me to be the best version of myself.</p>
      </div>
      
     
  </section>
</template>

<style scoped>

 #about p {
  font-weight: 300;
  /* position: relative; */
 }

 .section-content p {
  margin-right: unset;
  /* text-align: justify; */
 }
 .section-content .desktop-image,
 .section-content .mobile-image {
  text-align: center;
  position: relative;
  max-width: 430px;
  /* shape-outside: circle(50%); */
  shape-outside: polygon(33.53% 34px, 107.69% 6.54%, 107.52% 94.75%, 121px 95.24%, 8px 56.3%);
  margin: 20px;
  shape-margin: 20px;
  float: right;
 }

 .section-content .mobile-image {
  display: none;
  width: 60vw;
  /* max-width: 350px; */
  margin: 20px 8px;
  margin-right: -75px;
  shape-outside: polygon(41.14% 19px, 111.02% 3.61%, 108.36% 94.7%, 107px 95.09%, -4px 54.33%);
 }

 .rilindtasholli {
  position: relative;
 }
 
 .rilindtasholli::before {
  content: '';
  position: absolute;
  left: -1px;
  bottom: 0;
  width: 102%;
  height: 37%;
  background-color: #0abf80;
  opacity: 0.5;
  z-index: -1;
 }

   /*-------------- Tablet/Mobile media queries --------------*/

 @media (max-width: 1024px) {
  .section-content .desktop-image {display: none}
  .section-content .mobile-image {display: unset}
 }

 @media (max-width: 800px) {
    .section-content .mobile-image {
      shape-outside: polygon(2.55% 265px, 25.23% 186px, 47.77% 34px, 111.02% 6.17%, 108.36% 93.69%, 106px 92.3%, 90px 72.75%);
    }
  }

 @media (max-width: 480px) {
    .section-content .mobile-image {
      width: 240px;
      margin-right: -80px;
      /* shape-outside: polygon(64.5% 24px, 109.9% 5.68%, 112.29% 92.93%, 92px 94%, 11px 54.23%); */
      shape-outside: polygon(3.71% 189px, 28.7% 132px, 53.55% 28px, 111.6% 8.44%, 106.63% 91.7%, 75px 91.16%, 70px 74.74%);
    }
  }

</style>