<template>
    <div id="sidebar">
        <button id="toggle-sidebar-btn" @click="toggleSidebar">
            <i class="fas fa-chevron-right"></i>
        </button>
        <nav>
            <ul class="nav-list">
                <li @click.prevent="handleSelect" class="nav-item active" data-section-id="home">
                    <i class="fas fa-home"></i>
                    <span class="nav-item-text">Home</span>
                    
                    <span class="tooltiptext">Home</span>
                </li>
                <li @click.prevent="handleSelect" class="nav-item" data-section-id="skills">
                    <i class="fas fa-chart-simple"></i>
                    <span class="nav-item-text">Skills</span>

                    <span class="tooltiptext">Skills</span>
                </li>
                <li @click.prevent="handleSelect" class="nav-item" data-section-id="experience">
                    <i class="fas fa-timeline"></i>
                    <span class="nav-item-text">Experience</span>

                    <span class="tooltiptext">Experience</span>
                </li>
                <li @click.prevent="handleSelect" class="nav-item" data-section-id="work">
                    <i class="fas fa-briefcase"></i>
                    <span class="nav-item-text">Work</span>

                    <span class="tooltiptext">Work</span>
                </li>
                <li @click.prevent="handleSelect" class="nav-item" data-section-id="about">
                    <i class="fas fa-circle-info"></i>
                    <span class="nav-item-text">About</span>

                    <span class="tooltiptext">About</span>
                </li>
                <li @click.prevent="handleSelect" class="nav-item" data-section-id="contact">
                    <i class="fas fa-address-card"></i>
                    <span class="nav-item-text">Contact</span>

                    <span class="tooltiptext">Contact</span>
                </li>
            </ul>
        </nav>

        <label class="eye-toggle-btn">
            <input @change="handleToggleEye" type="checkbox" :checked="eyeActive ? true : null">
            <span class="slider round"></span>
        </label>
    </div>
</template>

<script>

export default {
    data() {
        return {
            activeSection: 'home'
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('load', this.handleScroll);

        document.addEventListener('click', this.handleDocumentClick);
    },
    // unmounted () {
    //     window.removeEventListener('scroll', this.handleScroll);
    // },
    computed: {
        eyeActive: () => localStorage.getItem('eyeActive') === 'true',
    },
    watch: {
        activeSection(newValue){
            //remove active class for every nav item
            document.querySelectorAll('.nav-item').forEach((item) => {
                item.classList.remove('active')
            })

            //set active class
            document.querySelector(`[data-section-id="${newValue}"]`).classList.add('active');
        }
    },
    methods: {
        toggleSidebar () {
            document.getElementById("sidebar").classList.toggle("open")
        },
        handleSelect (e) {
            this.$emit("go-section", e.currentTarget.getAttribute('data-section-id'));
        },
        handleToggleEye(e) {
            // const eyeActive = localStorage.getItem('eyeActive') === 'true'
            const eyeActive = e.target.checked
            localStorage.setItem('eyeActive', eyeActive)

            if(eyeActive) this.$emit("go-section", 'home'); //if eye is activated, scroll to Top
        },
        handleScroll() {
            this.activeSection = this.getCurrentSectionId()
        },
        handleDocumentClick(e) {
            //close sidebar when clicking anywhere except sidebar for tablet and mobile
            if(window.innerWidth < 1024) {
                var sidebar = document.getElementById('sidebar');
                if (!sidebar.contains(e.target)) {
                    sidebar.classList.remove('open')
                }
            }
        },
        getCurrentSectionId() {
            const sections = document.querySelectorAll('.section');

            var currentSectionId = "";

            sections.forEach((section) => {
                const rect = section.getBoundingClientRect();
                const sectionTop = rect.top + window.scrollY

                if(window.scrollY <= 20) {
                    currentSectionId = 'home'
                }else if (window.scrollY + (window.innerHeight / 2) >= sectionTop) {
                    //if sectionTop has reach in the middle of viewport height
                    currentSectionId = section.id;
                }
            })

            return currentSectionId
        }
    }
}
</script>


<style scoped>
    #sidebar {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 50px 25px;
        /* border-right: solid 1px rgba(86, 87, 87, 0.151); */
        background-color: #1d1f20;
        z-index: 20;
        box-shadow: 0px 0px 6px 1px rgba(10, 10, 10, 0.25);
    }

    nav {
        padding: 60px 0 0 0;
    }

    #toggle-sidebar-btn {
        position: absolute;
        top: 25px;
        left: 0;
        padding: 28px 15px;
        width: 100%;
        background-color: rgb(53, 58, 65);
        border: none;
        /* border-radius: 100px; */
        cursor: pointer;
        transition: .3s;
    }
    #toggle-sidebar-btn:hover {
        background-color: rgb(69, 74, 83);
    }
    #toggle-sidebar-btn i {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 6px 10px;
        margin: 0 10px;
        color: white;
        font-size: 1.2em;
        /* background-color: rgb(94, 103, 114); */
        transform: translate(-85%, -50%);
        /* border: dashed 1px red; */
        border-radius: 100%;
        transition: .45s;
    }
    #toggle-sidebar-btn:hover i {
        transform: translate(-70%, -50%);
    }
    #sidebar.open #toggle-sidebar-btn {
        background-color: rgb(10, 191, 128);
    }
    #sidebar.open #toggle-sidebar-btn:hover {
        background-color: rgb(17, 167, 122);
    }
    #sidebar.open #toggle-sidebar-btn i {
        background-color: rgb(94, 108, 114, 0.5);
        left: 100%;
        transform: rotate(-180deg) translateY(-50%) translateX(200%);
        transform-origin: center top;
        transition: .5s;
    }

    #sidebar .nav-item .nav-item-text {
        display: block;
        text-align: left;
        width: 100%;
        max-width: 0;
        opacity: 0;
        padding-right: 0;
        transition: .2s ease;
    }
    #sidebar.open .nav-item .nav-item-text {
        opacity: 1;
        max-width: unset;
        padding-left: 15px;
        transition: .3s ease;
        /* animation: openSidebar .5s ease-in-out forwards; */
    }
    #sidebar.open .nav-item {
        border-radius: 15px;
        padding: 20px;
    }

    .nav-list {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        list-style-type: none;
        gap: 8px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }

    .nav-item {
        display: flex;
        align-items: center;
        /* gap: 15px; */
        color: white;
        background-color: transparent;
        padding: 15px;
        height: 50px;
        border-radius: 100%;
        font-size: 1.3em;
        transition: all 0.7s, color 0.1s, background-color 0.1s, border-radius 0.4s;
    }
    
    .nav-item:active {
        transform: scale(1);
        transition: .05s;
    }

    .nav-item.active,
    .nav-item.active .nav-item-text {
        color: rgb(10, 191, 128);
    }

    .nav-item .fas {
        position: relative;
        display: flex;
        justify-content: center;
        min-width: 26px;
        transition: .3s;
    }

    .nav-item .tooltiptext {
        opacity: 0;
        width: 0;
        background-color: #484f58;
        color: #fff;
        text-align: center;
        border-radius: 100px;
        padding: 5px 20px;

        position: absolute;
        transform: translateX(50px) rotateY(90deg);
        transform-origin: left;
        z-index: 1;
        pointer-events: none;
        overflow: hidden;
        transition: transform .5s, color .9s;
    }

    .nav-item.active .tooltiptext {
        color: rgb(10, 191, 128);
    }

    #sidebar:not(.open) .nav-item:hover .tooltiptext {
        width: auto;
        opacity: 1;
        transition: transform .35s;
        transition-delay: .65s;
        transform: translateX(50px) rotateY(0);
    }



    .eye-toggle-btn {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .eye-toggle-btn input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        display: flex;
        justify-content: center;
        align-items: center;
        content: "\f070";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: rgb(10, 191, 128);
        box-shadow: 0px 0px 10px 0px rgb(10, 191, 128, 0.6);
    }

    /* input:focus + .slider {
        box-shadow: 0 0 1px rgb(10, 191, 128);
    } */

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
        font-family: 'Font Awesome 5 Free';
        content: "\f06e";
        color: rgb(10, 191, 128);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    /*---------------- Desktop Only media query ----------------*/

    @media (min-width: 1024px) {
        .nav-item:hover {
            transform: scale(1.05);
            background-color: rgb(72, 79, 88);
            cursor: pointer;
            transition: background-color .3s, transform .15s;
        }
    }

    
    /*-------------- Tablet/Mobile media queries --------------*/

    @media (max-width: 1024px) {
        #sidebar {
            padding: 50px 10px;
            justify-content: center;
        }

        #toggle-sidebar-btn {
            top: 8%;
        }

        .eye-toggle-btn {
            display: none;
        }

        .nav-list {
            margin-top: -100px;
        }

        #toggle-sidebar-btn:hover {
            background-color: rgb(53, 58, 65);
        }
        #toggle-sidebar-btn:hover i {
            transform: translate(-85%, -50%);
        }

        #sidebar.open .nav-item {
            border-radius: 15px;
            padding: 20px;
            margin: 0 25px;
        }

        .nav-item.active {
            transition: all 0.7s, color 0.1s, background-color 1s, border-radius 0.4s;
            background-color: rgb(72, 79, 88);
        }

        #sidebar:not(.open).nav-item:hover {
            background-color: transparent;
        }
     
 
        .nav-item:active {
            background-color: rgb(72, 79, 88);
        }

         #sidebar:not(.open) .nav-item:hover .tooltiptext {
            opacity: 0;
            transform: unset;
        }
        #sidebar:not(.open) .nav-item:active .tooltiptext {
            width: auto;
            opacity: 1;
            transition: transform .35s;
            transition-delay: .65s;
            transform: translateX(50px) rotateY(0);
        }

    }

    @media (max-width: 480px) {
        #sidebar {
            padding: 50px 5px;
        }
        .nav-item {
            padding: 13px;
            font-size: 1.1em;
            margin: 0 3px;
        }
    }
</style>

