<template>
  <section id="experience" class="section">
        <h2>Experience</h2>
        <p>Studying Computer Science and Engineering at <strong>University of Business and Technology (UBT)</strong> has provided me with a strong foundation of knowledge in programming languages, software engineering, databases, networking, algorithms and technical skills, which has prepared me to succeed in this evolving industry.</p>
        <!-- <p>Completing various courses and trainings in the fields of digital skills and web development, as well as working with various companies to develop real-world projects, have strengthened my skills and knowledge in these areas.</p> -->
        <p>Completing various courses and trainings in the fields of digital skills and web development, as well as working on real-world projects, have strengthened my skills and knowledge in these areas.</p>
        <p>I'm currently working as a Frontend Developer at <strong>a&o Hostels</strong>, dedicated to enhance the user experience for their customers and employees. As a member of the development team, I contribute to the development and improvement of various web projects that are part of the company and align with its goals and vision.</p>
        <div class="section-content">
          
          <div class="timeline-container">
            <img class="shapes-image" src="@/assets/img/background/shapes-image.svg" alt="Shapes Background Image"/>

            <div class="work-experience">
              <h3>Work Experience</h3>

              <div class="timeline">
                <div class="timeline-item wow animate__fadeInDown" data-wow-duration="1s">
                  <span class="timeline-date-location"><span>18/10/2022</span> - Berlin, Germany</span>
                  <h4 class="timeline-title">Frontend Developer</h4>
                  <h5 class="timeline-company">a&o Hostels</h5>
                  <a class="timeline-url" target="_blank" href="https://www.aohostels.com">www.aohostels.com</a>
                </div>
              </div>

              <div class="timeline">
                <div class="timeline-item wow animate__fadeInDown" data-wow-duration="1.3s">
                  <span class="timeline-date-location"><span>07/04/2020</span> - Ferizaj, Kosovo</span>
                  <h4 class="timeline-title">IT & Web Developer</h4>
                  <h5 class="timeline-company">407 ArchStory</h5>
                  <a class="timeline-url" target="_blank" href="https://www.407archstory.com">www.407archstory.com</a>
                </div>
              </div>
            </div>
            <div class="education-training">
              <h3>Education and Training</h3>

              <div class="timeline">
                <div class="timeline-item wow animate__fadeInDown" data-wow-duration="1.3s">
                  <span class="timeline-date-location"><span>03/03/2022</span> - Kosovo</span>
                  <h4 class="timeline-title">ICT & Digital Skills Training on Web Development Professional</h4>
                  <h5 class="timeline-company">ICT for Kosovo's Growth</h5>
                  <a class="timeline-url" target="_blank" href="https://ictkosovo.eu">www.ictkosovo.eu</a>
                </div>
              </div>

              <div class="timeline">
                <div class="timeline-item wow animate__fadeInDown" data-wow-duration="1.3s">
                  <span class="timeline-date-location"><span>11/10/2021</span> - Kosovo</span>
                  <h4 class="timeline-title">ICT & Digital Skills Training on Web Development Practitioner</h4>
                  <h5 class="timeline-company">ICT for Kosovo's Growth</h5>
                  <a class="timeline-url" target="_blank" href="https://ictkosovo.eu">www.ictkosovo.eu</a>
                </div>
              </div>

              <div class="timeline">
                <div class="timeline-item wow animate__fadeInDown" data-wow-duration="1.3s">
                  <span class="timeline-date-location"><span>14/10/2019</span> - Prishtine, Kosovo</span>
                  <h4 class="timeline-title">Bachelor of Computer Science & Engineering</h4>
                  <h5 class="timeline-company">University of Business and Technology (UBT)</h5>
                  <a class="timeline-url" target="_blank" href="https://www.ubt-uni.net">www.ubt-uni.net</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
</template>

<style scoped>
  .shapes-image {
    position: absolute;
    /* top: 0; */
    /* right: -66%; */
    /* border: dashed 1px red; */
    /* width: 100%; */
    height: 70vh;
    max-height: 700px;
    opacity: .2;
    /* transform-origin: right; */
    transform: rotate(-90deg) translateY(-45%);
    z-index: -1;
  }

  .timeline-container {
    position: relative;
    margin-left: 2vw;
  }

  .timeline-container h3 {
    margin: 20px 0;
    opacity: .85;
    text-align: left;
    text-transform: uppercase;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    background-color: #0abf80;
    width: 3px;
    min-height: 150px;
    display: flex;
    margin-left: 10px;
  }

  .timeline-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    position: relative;
    left: 50px;
    text-align: left;
    width: 65vw;
    padding: 25px 0;
    /* border: dashed 1px red; */
  }

  .timeline-date-location {
    text-align: left;
    /* white-space: nowrap; */
    opacity: .75;
    font-size: .93em;
  }
  .timeline-date-location span {
    font-weight: bold;
    font-size: 1.04em;
    letter-spacing: .03em;
  }

  .timeline-title {
    display: flex;
    align-items: center;
    width: 100%;
    /* white-space: nowrap; */
    position: relative;
    font-size: 1.2em;
  }

  .timeline-title::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border: solid 6px #18191a;
    background-color:  #0abf80;
    border-radius: 100px;
    left: -62px;
  }

  .timeline-company {
    opacity: 0.8;
    font-weight: normal;
    font-size: 1.1em;
  }

  .timeline-url,
  .timeline-url:visited {
    color: rgb(112, 170, 197);
  }

  /*-------------- Tablet/Mobile media queries --------------*/

  @media (max-width: 800px) {
    .timeline-item {
      left: 30px;
      /* border: dashed 1px red; */
    }

    .timeline-title::before {
      left: -42px;
    }
  }

  @media (max-width: 500px) {
    .timeline-container {
      margin-left: -5px;
    }
    .timeline-item {
      width: 68vw;
    }
  }

  @media (max-width: 420px) {
    .timeline-item {
      width: 55vw;
    }
  }

</style>